export const topNavigation = [{"text":"Alle Weine","link":{"href":"/wein"},"icon":null,"subLinks":null},{"text":"Bubbles","link":{"href":"/perl-schaumwein"},"icon":null,"subLinks":null},{"text":"Tanja Grandits","link":{"href":"/edvinxgrandits"},"icon":null,"subLinks":null},{"text":"Geschenke","link":{"href":"/geschenke"},"icon":null,"subLinks":null},{"text":"Special Sets","link":{"href":"/paeckli"},"icon":null,"subLinks":null},{"text":"Alkoholfrei","link":{"href":"/alkoholfrei"},"icon":null,"subLinks":null},{"text":"Events","link":{"href":"/events"},"icon":null,"subLinks":null},{"text":"Mehr","link":{"href":"/"},"icon":null,"subLinks":[{"text":"Aktionen","link":{"href":"/xmas-aktion1"}},{"text":"News","link":{"href":"/blog"}},{"text":"Über uns","link":{"href":"/team"}},{"text":"Beratung","link":{"href":"/beratung"}},{"text":"Weingüter","link":{"href":"/weingueter"}},{"text":"Merchandise","link":{"href":"/allerlei"}}]}]

export const footerMenu = [{"text":"News","link":{"href":"/blog"},"icon":"nav_wissen"},{"text":"Events","link":{"href":"/events"},"icon":"nav_events"},{"text":"Shop","link":{"href":"/wein"},"icon":"nav_shop"},{"text":"Meine Traumweine finden","link":{"href":"/traumweine"},"icon":"nav_trophy_line"}]

export const helpFooter = [{"title":"Öffnungszeiten Weinladen Aarau","text":"Di-Fr 10:00 - 19:00 | Sa 10:00 - 16:00","link":{"href":"/blog/edvin-weinladen-aarau"},"icon":"nav_clock"},{"title":"Lieferung","text":"Bestellungen ab CHF 180.- werden portofrei geliefert. Bis zu diesem Betrag werden CHF 9.90 verrechnet.","link":{"href":"/faq#-versandkosten"},"icon":"nav_delivery"},{"title":"Rücksendungen","text":"Der Wein entspricht nicht deinen Vorstellungen? Oder hat Zapfen? Kontaktiere uns.","link":{"href":"/faq#-retoure"},"icon":"nav_return"},{"title":"Zahlungsarten","text":"Wir bieten eine Vielzahl an Zahlungsmöglichkeiten an, damit alle den passenden Weg finden.","link":{"href":"/faq#-payment"},"icon":"nav_payment"}]

export const footer = [{"text":"Kontakt","link":{"href":"/kontakt"}},{"text":"Presse","link":{"href":"/presse"}},{"text":"Newsletter","link":{"href":"/newsletter"}},{"text":"FAQ","link":{"href":"/faq"}}]

export const socialMediaFooter = [{"label":"Instagram","link":{"href":"https://www.instagram.com/edvin_weine/","target":"_blank","rel":"noopener"},"icon":"social_insta"},{"label":"Facebook","link":{"href":"https://www.facebook.com/Edvin.Weine","target":"_blank","rel":"noopener"},"icon":"social_fb"},{"label":"spotify","link":{"href":"https://open.spotify.com/user/116755463?si=VzIjp5Y5QqiUHKSsuiF30g","target":"_blank","rel":"noopener"},"icon":"social_spotify"}]

export const legalFooter = [{"text":"Impressum","link":{"href":"/impressum"}},{"text":"AGB","link":{"href":"/agb"}},{"text":"Datenschutz","link":{"href":"/datenschutz"}}]