import NextHead from 'next/head';
import { useRouter } from 'next/router';
import { t } from '../../generated/i18n';
import { BASE_URL } from '../../lib/config';
import { CartOverlay } from '../cart/cart-overlay';
import { Footer } from './footer';
import { Nav } from './nav/nav';
import { Privacy } from './privacy';

export default function Layout({
  children,
  preview = false,
  title = null,
  showFooter = true,
  className = '',
  description = '',
  ogImage = null,
  canonicalUrl = '',
  robots = 'all',
  ldJson = null,
}) {
  title = title ? `${title} | Edvin Weine` : t('edvinTitle');
  description = (description ? description : t('edvinDescription')).slice(
    0,
    159,
  );
  const router = useRouter();
  const url = `${BASE_URL}${router.asPath}`;

  return (
    <>
      <NextHead>
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: `[{"@context": "http://schema.org",
"@type": "WebSite",
"name": "Edvin Weine",
"url": "https://edvinweine.ch/",
"potentialAction": {
"@type": "SearchAction",
"target": "https://edvinweine.ch/suchen?q={search_term_string}",
"query-input": "required name=search_term_string"
}}
${ldJson ? ',' + ldJson : ''}
]`,
          }}
        />
        <meta charSet='UTF-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <meta httpEquiv='x-ua-compatible' content='ie=edge' />
        <title>{title}</title>
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:creator' content='edvinweine.ch' />
        <meta name='twitter:title' content={title} />
        <meta name='twitter:description' content={description} />
        <meta property='og:title' content={title} />
        <meta
          name='description'
          property='og:description'
          content={description}
        />
        <meta name='type' property='og:type' content={`website`} />
        <meta name='url' property='og:url' content={url} />
        <meta
          name='image'
          property='og:image'
          content={
            ogImage ? ogImage : `${BASE_URL}/api/static/open_graph_image`
          }
        />
        <link rel='dns-prefetch' href='https://edwinweine.imgix.net' />
        <link rel='dns-prefetch' href='https://images.prismic.io' />
        <link rel='dns-prefetch' href='https://api.edvinweine.ch/' />
        <link rel='canonical' href={canonicalUrl ? canonicalUrl : url} />
        <link
          rel='apple-touch-icon'
          sizes='57x57'
          href='/static/favicon/apple-icon-57x57.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='60x60'
          href='/static/favicon/apple-icon-60x60.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='72x72'
          href='/static/favicon/apple-icon-72x72.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='76x76'
          href='/static/favicon/apple-icon-76x76.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='114x114'
          href='/static/favicon/apple-icon-114x114.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='120x120'
          href='/static/favicon/apple-icon-120x120.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='144x144'
          href='/static/favicon/apple-icon-144x144.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='152x152'
          href='/static/favicon/apple-icon-152x152.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/static/favicon/apple-icon-180x180.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='192x192'
          href='/static/favicon/android-icon-192x192.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/static/favicon/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='96x96'
          href='/static/favicon/favicon-96x96.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/static/favicon/favicon-16x16.png'
        />
        <link
          href='https://fonts.googleapis.com/css?family=Lora:700|Open+Sans:400,700&display=swap'
          rel='stylesheet'
        />
        <link rel='manifest' href='/static/favicon/manifest.json' />
        <meta name='msapplication-TileColor' content='#E7EDE7' />
        <meta
          name='msapplication-TileImage'
          content='/static/favicon/ms-icon-144x144.png'
        />
        <meta name='theme-color' content='#E7EDE7' />
        <meta name='robots' content={robots} />
        <meta
          name='facebook-domain-verification'
          content='gryaenv4rboafblsu2pxafu91s9lbe'
        />
        <meta
          name='google-site-verification'
          content='uaN8Z0ZvYhdIY5QGcXvFFO5HuZxJpLlA3jYWo9RDGtA'
        />
        <meta
          name='google-site-verification'
          content='RwhrdpFlOTLNSp1kolgNbbjIVGa5ODB7vmuXvLyrf7k'
        />
      </NextHead>

      <div className={`overflow-x-hidden ${className}`}>
        <Nav />
        <CartOverlay />
        <main className='min-h-screen'>{children}</main>
        {showFooter && <Footer query={router.query} />}
        {preview && (
          <div className='bg-red1 text-white' role='alert'>
            <div className='mx-auto max-w-4xl p-10'>
              <b>Vorschaumodus:</b>
              <br /> Die Änderungen sind nicht veröffentlicht
              <a href='/api/exit-preview' className='mt-2 block underline'>
                Vorschau verlassen
              </a>
            </div>
          </div>
        )}
      </div>
      <Privacy className='sticky bottom-0' />
    </>
  );
}
